import React, { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";

const NbrGuests = ({ selectedValue = 6, handleChangeNbrGuests }) => {
  const [value, setValue] = useState(selectedValue);

  useEffect(() => {
    // trigger api call only when done typing and if value different from db
    const validValue = !!value && value > 0
    let timer = setTimeout(() => {
      if (validValue && value !== selectedValue) handleChangeNbrGuests(value);
    }, 1000);
    return () => clearTimeout(timer);
  }, [value]);

  const handleChangeValue = (e) => {
    if (e.target.value.length > 4){
      setValue(9999)
    } else {
      setValue(e.target.value);
    }
  };

  const handlePreventNullValues = () => {
    if (!value || value < 1) {
      setValue(selectedValue);
    }
  };

  return (
    <section className="container-select-nbr-guests">
      <h4>
        <FormattedMessage
          id="settings.nbr-guests"
          defaultMessage="Maximum de couverts"
        />
      </h4>
      <div className="input-select-nbr-guests">
        <div className="select-styles">
          <input
            type="number"
            min="1"
            max="9999"
            className="input-nbr-guests"
            onChange={(e) => handleChangeValue(e)}
            value={value}
            onBlur={() => handlePreventNullValues()}
          />
        </div>
        <span>
          <FormattedMessage id="settings.fork" defaultMessage="couverts" />
        </span>
      </div>
    </section>
  );
};

export default NbrGuests;

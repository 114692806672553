import {useEffect, useState} from "react";
import { getCookie, getHostUrl } from "../utils/function";
import axios from "axios";
import decode from "jwt-decode";

export default function useAuth() {

  const refreshToken = getCookie('refreshToken');
  const accessToken = window.sessionStorage.getItem('accessToken');

  const [isLogged, setIsLogged] = useState(false);
  const [tryLogin, setTryLogin] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);


  useEffect(() => {
    if (!tryLogin) {
      isLogin();
    }
  }, []);
  const isLogin = async () => {

    if (!refreshToken) {
      window.location = getHostUrl('auth') + '/?scope=reservation'
    }

    if (accessToken && compareToken(refreshToken, accessToken)) {
      setIsLogged(true);
      setTryLogin(true);
      return;
    }

    try {
      await getAccessToken(refreshToken);
      setIsLogged(true);
    } catch (e) {
      setUnauthorized(checkUnauthorizedService(e));
      setIsLogged(false);
    } finally {
      setTryLogin(true);
    }
  };

  return { isLogged, tryLogin, unauthorized, refreshToken };
}

const checkUnauthorizedService = (e) => {
  const responseData = e.response.data;
  return !!(responseData && responseData.code === "SUB_USER_NO_ACCESS");
}

const getAccessToken = async refreshToken => {
  const getAccessTokenPath = '/api/auth/accessToken';
  let res = await axios({
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
    url: `${getAccessTokenPath}/?refreshToken=${refreshToken}`,
  });
  const accessToken = res.data.accessToken;
  sessionStorage.setItem('accessToken', accessToken);
  return accessToken;
};

const compareToken = (refreshToken, accessToken) => {
  const decodedRefreshToken = decode(refreshToken);
  const decodedAccessToken = decode(accessToken);

  return decodedRefreshToken.id === decodedAccessToken.id;
}
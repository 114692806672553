import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { withSnackbar } from "notistack";
import Popup from "../common/Popup";
import CallApi from "./../../utils/CallApi";
import logoLaddition from "./../../images/logo.png";
import moment from "moment";
import ClipboardJS from "clipboard";
import UrlCustom from "./UrlCustom";
const host = window.location.origin;

class Integration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantId: props.restaurantId,
      connect: false,
      scriptWebCopy: false,
      copy: false,
      openingHoursNotEmpty: false,
      tokenLaddition: props.tokenLaddition,
      token: props.token,
      tokenPost: "",
      tokenError: "",
      disableApp: props.disableApp,
      disableAppPopup: false,
      disablaAppSelectedOptionName: "",
      disableAppPeriod: props.disableAppPeriod ?? {},
      disableAppPeriodMorning: false,
      disableAppPeriodEvening: false,
      errror: "",
      websiteUrl: props.alias,
      displayPopup: false,
    };
  }

  componentWillMount(e) {
    const { disableApp, disableAppPeriod } = this.state;
    if (disableApp && disableAppPeriod && disableAppPeriod.date) {
      const date = moment(disableAppPeriod.date, "YYYY-MM-DD");
      const today = moment(new Date()).format("YYYY-MM-DD");
      if (moment(date).isBefore(today)) {
        this.handleDisplayApp(e, false);
      }
    }
  }

  componentDidMount() {
    const clip1 = new ClipboardJS(".url");
    const clip2 = new ClipboardJS(".script-web");
    const web = false;
    clip1.on("success", () => {
      this.setState(
        {
          scriptWebCopy: !!web ? true : false,
          copy: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              scriptWebCopy: false,
              copy: false,
            });
          }, 2500);
        }
      );
    });
    clip2.on("success", () => {
      this.setState(
        {
          scriptWebCopy: !!web ? true : false,
          copy: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              scriptWebCopy: false,
              copy: false,
            });
          }, 2500);
        }
      );
    });
    const { openingHours } = this.props;
    if (!this.state.openingHoursNotEmpty && openingHours) {
      const hoursNotEmpty = this.checkHoursNotEmpty(openingHours);
      this.setState({ openingHoursNotEmpty: hoursNotEmpty });
    }
  }

  componentDidUpdate() {
    if (this.state.test === true && this.state.statusPost !== true) {
      this.setState({
        statusPost: true,
        displayPopup: false,
      });
    }
  }

  checkHoursNotEmpty(hours) {
    const arrayHours = Object.entries(hours);
    const openingHoursNotEmpty = arrayHours.some((day) => day[1].length);
    return openingHoursNotEmpty;
  }

  handleDisplayApp = async (e, bool) => {
    // function call in component will mount (if option is today and now Date() !== today)
    if (e) {
      e.preventDefault();
    }

    const checkPeriod = bool ? this.state.disableAppPeriod : null;
    const res = await CallApi(
      "put",
      "restaurants",
      this.state.restaurantId,
      {
        disableApp: bool,
        disableAppPeriod: checkPeriod,
      },
      this.props.token
    );

    if (res[0] === "OK") {
      this.setState({
        disableApp: !this.state.disableApp,
        disableAppPopup: false,
      });
     
      if (!bool || (bool && this.state.disableAppPeriod.date === "all" )) {
        const state = bool ? "disabled" : "activate"
        this.props.onUpdateRulesStatus(state)
      }

      this.props.onUpdateDisableApp(bool, checkPeriod);
      this.displaySuccess();
    } else {
      this.displayError();
    }
  };

  displayPopup = (e, bool) => {
    e.preventDefault();
    if (!bool) {
      this.setState({
        displayPopup: bool,
      });
    } else {
      this.setState({
        displayPopup: bool,
      });
    }
  };

  handleContentPopup = (e, name, bool) => {
    e.preventDefault();
    let { disableAppPopup } = this.state;
    if (name === "disableAppPopup") {
      disableAppPopup = bool;
    }
    this.setState({
      disableAppPopup: disableAppPopup,
    });
  };

  handleOptionChange = (e, option) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let { ...disableAppPeriod } = this.state.disableAppPeriod ?? {};
    const optionName = value;

    if (optionName === "today") {
      disableAppPeriod.date = moment(new Date()).format("YYYY-MM-DD");
    } else if (optionName === "all") {
      disableAppPeriod.date = "all";
    }

    // if handleOptionChange call from radio button option with period
    if (option) {
      const selectedDate = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
      if (moment(value).isValid()) {
        disableAppPeriod.date = selectedDate;
      } else {
        disableAppPeriod.period = value;
      }
    }

    this.setState({
      [name]: value,
      disableAppPeriod: disableAppPeriod,
    });
  };

  handleInputUrl = (e) => {
    const value = e.target.value.replace(
      /[ !@#$€%^&*;é§èçà°(),.?":{}|<>+/'`=]/g,
      "-"
    );
    if (
      value.length > 1 &&
      value[value.length - 1] === "-" &&
      value[value.length - 2] === "-"
    )
      return;
    const newValue = value.toLowerCase();
    this.setState({ websiteUrl: newValue });
  };

  handleBlurUrl = async (e) => {
    const { websiteUrl } = this.state;
    const { alias } = this.props;
    if (websiteUrl !== alias) {
      const dataSent = await this.submitData();
      if (dataSent.success) {
        if (dataSent.existingAlias) {
          this.displayWarning();
          return;
        }
        this.displaySuccess();
        this.props.onUpdateUrl(this.state.websiteUrl);
      } else {
        this.displayError();
      }
    }
  };

  submitData = async () => {
    const { restaurantId, websiteUrl } = this.state;
    const res = await CallApi(
      "put",
      "restaurants",
      restaurantId,
      {
        alias: websiteUrl,
      },
      this.props.token
    );
    return {
      success: !!res[0],
      existingAlias: res[0].existingAlias,
    };
  };

  displayWarning = () => {
    this.props.enqueueSnackbar(
      <FormattedMessage
        id="integrate.adminErrorUrl"
        defaultMessage="Cette url n’est pas disponible, veuillez la modifier"
      />,
      { variant: "warning" }
    );
  };

  displaySuccess = () => {
    this.props.enqueueSnackbar(
      <FormattedMessage
        id="common.success"
        defaultMessage="Vos changements ont bien été pris en compte."
      />,
      { variant: "success" }
    );
  };

  displayError = () => {
    this.props.enqueueSnackbar(
      <FormattedMessage
        id="common.errorRequest"
        defaultMessage="Oups... nous rencontrons un problème avec l'enregistrement de vos données"
      />,
      { variant: "error" }
    );
  };

  render() {
    const { disableAppPeriod, disableApp, websiteUrl } = this.state;
    const linkUserModule = `${host}/booking/${
      websiteUrl ? websiteUrl : this.props.restaurantId
    }`;
    const error = {
      openingHours: `Attention ! vous n'avez pas remplis vos horaires d'ouvertures. `,
    };

    const tokenLadditionInfo = (
      <FormattedMessage
        id="info.connect-ladditon-success"
        defaultMessage="Votre module de réservation est bien connecté avec votre caisse."
      />
    );

    const selectOptionDisableApp = () => {
      return (
        <form>
          <div className="form-disableApp">
            <div className="radio">
              <input
                type="radio"
                value="all"
                id="all"
                name="disablaAppSelectedOptionName"
                onChange={this.handleOptionChange}
              />
              <label for="all">
                <FormattedMessage
                  id="integrate.disableAppPopupAll"
                  defaultMessage="Jusqu'a nouvel ordre"
                />
              </label>
            </div>
            <div className="radio">
              <input
                type="radio"
                value="today"
                id="today"
                name="disablaAppSelectedOptionName"
                onChange={this.handleOptionChange}
              />
              <label for="today">
                <FormattedMessage
                  id="integrate.disableAppPopupToday"
                  defaultMessage="Pour aujourd'hui"
                />
              </label>
            </div>
          </div>
          {this.state.errror ? this.state.errror : ""}
          <button
            className={`is-red ${
              (this.state.disablaAppSelectedOptionName &&
                this.state.disablaAppSelectedOptionName !== "option") ||
              (this.state.disablaAppSelectedOptionName === "option" &&
                this.state.disablaAppSelectedOptionDate &&
                this.state.disablaAppSelectedOptionPeriod)
                ? "isValidated"
                : "disabled"
            }`}
            onClick={(e) => this.handleDisplayApp(e, true)}
          >
            <FormattedMessage id="common.validate" defaultMessage="Valider" />
          </button>
        </form>
      );
    };

    const ctaDisableModule = (
      <div id="disable-module">
        <p className="info">
          <FormattedMessage
            id="integrate.disableAppInfo"
            defaultMessage=" Vous pouvez désactiver temporairement votre module de réservation afin de bloquer la prise de réservation, et le réactiver lorsque vous le souhaitez."
          />
        </p>
        <button
          className={`is-red`}
          onClick={(e) => this.handleContentPopup(e, "disableAppPopup", true)}
        >
          <FormattedMessage
            id="integrate.disableAppCTA"
            defaultMessage="Désactiver le module"
          />
        </button>
        {this.state.disableAppPopup && !disableApp ? (
          <Popup
            title={
              <FormattedMessage
                id="integrate.disableAppPopupTitle"
                defaultMessage="Désactiver le module"
              />
            }
            content={selectOptionDisableApp()}
            close={(e) => this.handleContentPopup(e, "disableAppPopup", false)}
          />
        ) : (
          ""
        )}
      </div>
    );

    const ctaEnableModule = (
      <React.Fragment>
        {disableAppPeriod &&
        disableAppPeriod.date &&
        moment(disableAppPeriod.date, "YYYY-MM-DD").isValid() ? (
          <div className="disableApp-info">
            <p>
              <FormattedMessage
                id="integrate.disableAppInfoDate"
                defaultMessage="Votre module est désactivé pour le "
              />
              {moment(disableAppPeriod.date, "YYYY-MM-DD").format("DD/MM/YYYY")}{" "}
              {disableAppPeriod.period ? (
                <FormattedMessage
                  id="integrate.disableAppInfoPeriod"
                  defaultMessage="pour le "
                />
              ) : (
                ""
              )}
              {disableAppPeriod.period ? (
                <FormattedMessage
                  id={`hours.${[disableAppPeriod.period]}Lowercase`}
                  defaultMessage="midi"
                />
              ) : (
                ""
              )}
              .
            </p>
          </div>
        ) : (
          ""
        )}
        {disableAppPeriod &&
        disableAppPeriod.date &&
        disableAppPeriod.date === "all" ? (
          <div className="disableApp-info">
            <p>
              <FormattedMessage
                id="integrate.disableAppInfoDateAll"
                defaultMessage="Votre module est désactivé jusqu'à nouvel ordre."
              />
            </p>
          </div>
        ) : (
          ""
        )}
        <button
          onClick={(e) => this.handleDisplayApp(e, false)}
          className={`is-blue`}
        >
          <FormattedMessage
            id="integrate.enableAppCTA"
            defaultMessage="Réactiver le module"
          />
        </button>
      </React.Fragment>
    );

    const disableAppBlock = (
      <div className="disable-app">
        <h4>
          <FormattedMessage
            id="disable.title"
            defaultMessage="Désactivation temporaire de votre module"
          />
        </h4>
        {this.state.disableApp ? ctaEnableModule : ctaDisableModule}
      </div>
    );

    const tokenLadditionError = (
      <p>
        <FormattedMessage
          id="integrate.tokenLadditionError"
          defaultMessage="Un problème est survenu lors de la connexion entre votre caisse L'Addition et votre module de réservation. Veuillez nous contacter pour résoudre ce problème : {mail}"
          values={{
            mail: (
              <a href="mailto:support@laddition.com">support@laddition.com</a>
            ),
          }}
        />
      </p>
    );

    return (
      <div id="integration">
        <h3>
          <FormattedMessage
            id="integrate.title"
            defaultMessage="Intégration de votre module de réservation"
          />
        </h3>
        <div className="pure-g">
          <div className="pure-u-1 pure-u-lg-3-5">
            <section className="section-fb">
              <div className="link">
                {this.props.openingHours !== null || "" ? (
                  <div className="script-web">
                    <UrlCustom
                      websiteUrl={websiteUrl}
                      handleInputUrl={this.handleInputUrl}
                      handleBlurUrl={this.handleBlurUrl}
                    />
                    <p className="title bold">
                      <span className="dot" />
                      <FormattedMessage
                        id="integrate.less2000likes"
                        defaultMessage="via le bouton d’action"
                      />
                    </p>
                    <p className="title-link">
                      <FormattedMessage
                        id="integrate.tutoCopy"
                        defaultMessage="Lien à copier : "
                      />
                    </p>
                    <p>
                      <span
                        className="is-blue url"
                        data-clipboard-text={linkUserModule}
                      >
                        {linkUserModule}
                      </span>
                    </p>
                    {!this.state.scriptWebCopy && !!this.state.copy ? (
                      <p className="confirm-copy">
                        <FormattedMessage
                          id="integrate.pasteDone"
                          defaultMessage="Le lien a bien été copié dans votre presse-papier."
                        />
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  error
                )}
              </div>
              {disableAppBlock}
            </section>
          </div>
          <section id="laddition" className="pure-u-1 pure-u-lg-2-5">
            <h4>
              <img src={logoLaddition} alt="logo l'addition" />
              <FormattedMessage
                id="info.connect-laddition"
                defaultMessage="connexion avec votre caisse L'Addition"
              />
            </h4>
            {this.state.tokenLaddition
              ? tokenLadditionInfo
              : tokenLadditionError}
            <div className="container-steps-activation-resa">
              <p className="title-activate-resa">
                <FormattedMessage
                  id="info.activation-1"
                  defaultMessage="Pour activer la réception des réservations sur votre iPad principal :"
                />
              </p>
              <ul>
                <li>
                  <FormattedMessage
                    id="info.activation-2"
                    defaultMessage="Faites une synchronisation manuelle (dans les Réglages > A propos)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="info.activation-3"
                    defaultMessage="Relancez l’application L’Addition"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="info.activation-4"
                    defaultMessage="Allez dans les Réglages > Intégration partenaires > activez le bouton “Activation des modules partenaires”"
                  />
                </li>
              </ul>
              <p>
                <FormattedMessage
                  id="info.activation-5"
                  defaultMessage="Besoin d’aide ? Consultez le {link}"
                  values={{
                    link: (
                      <a href="https://cdn.laddition.com/7/ladditionreservationguideintegrationv14.pdf">
                        {" "}
                        <FormattedMessage
                          id="info.activation-6"
                          defaultMessage="“Guide - Intégrer L’Addition Réservation”"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
  static defaultProps = {
    openingHours: {},
    restaurantId: 1,
    token: "",
    disableApp: false,
  };
}

Integration.propTypes = {
  openingHours: PropTypes.object.isRequired,
  restaurantId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onUpdateTokenLaddition: PropTypes.func.isRequired,
  disableApp: PropTypes.bool.isRequired,
  disableAppPeriod: PropTypes.object,
};

export default withSnackbar(Integration);

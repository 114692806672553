import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const styles = {
  root: {
    width: 34 + 10 * 2,
    height: 20 + 10 * 2,
    padding: 10
  },
  colorPrimary: {
    '&$checked': {
      color: '#fafafa'
    },
    '&$checked + $track': {
      backgroundColor: '#5ac8f5',
      opacity: 1
    }
  },
  switchBase: {
    transform: 'translateX(-1px)',
    '&$checked': {
      transform: 'translateX(20px)'
    }
  },
  checked: {},
  disabled: {},
  track: {
    backgroundColor: '#d0d0d0',
    borderRadius: 20 / 2,
    opacity: 1
  }
};
class SwitchHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: !!props.hoursOfDay.length
    };
    this.deleteTimeRanges = this.deleteTimeRanges.bind(this);
    this.addRange = this.addRange.bind(this);
  }

  deleteTimeRanges(e, day) {
    this.setState({ checked: false }, () =>
      this.props.updateTimeRangeDay(e, day, {}, null, 'removeAll')
    );
  }

  addRange(e, day) {
    this.setState({ checked: true }, () => this.props.addTimeRange(e, day));
  }

  render() {
    const { hoursOfDay, classes, day } = this.props;
    const { checked } = this.state;
    return (
      <div className="container-switch-button-time">
        <Switch
          onChange={
            hoursOfDay.length
              ? e => this.deleteTimeRanges(e, day)
              : e => this.addRange(e, [day])
          }
          checked={checked}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          classes={{
            colorPrimary: classes.colorPrimary,
            switchBase: classes.switchBase,
            disabled: classes.disabled,
            checked: classes.checked,
            track: classes.track,
            root: classes.root
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SwitchHours);

import React, { Component } from "react";

import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import CallApi from "../../utils/CallApi";
import { withSnackbar } from "notistack";
import SwitchHours from "./SwitchHours";
import PropTypes from "prop-types";
import Slots from "./Slots";
class OpeningHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closingDate: props.closingDate,
      restaurantId: props.restaurantId,
      selectedDays: props.openingHours,
      addTimeRangeInProgress: false,
      indexTimeRangeUpdated: -1,
      isUpdateInProcess: false,
      formateOpeningHours: [],
      dataSentSuccess: false,
      addingNewHours: false,
      rdcToSettings: false,
      openSnackBar: false,
      maxSlotTimesDays: 0,
      displaySlotTimes: 0,
      slotUpdated: null,
      emptySlot: [
        {
          monday: "",
        },
        {
          tuesday: "",
        },
        {
          wednesday: "",
        },
        {
          thursday: "",
        },
        {
          friday: "",
        },
        {
          saturday: "",
        },
        {
          sunday: "",
        },
      ],
      success: false,
      dayUpdated: "",
      errors: "",
    };
    this.formateOpeningHours = this.formateOpeningHours.bind(this);
    this.updateTimeRangeDay = this.updateTimeRangeDay.bind(this);
    this.timeRangeUpdated = this.timeRangeUpdated.bind(this);
    this.updateDaysOfWeek = this.updateDaysOfWeek.bind(this);
    this.createArraySlot = this.createArraySlot.bind(this);
    this.addTimeRange = this.addTimeRange.bind(this);
    this.displayError = this.displayError.bind(this);
    this.goBottomPage = this.goBottomPage.bind(this);
    this.scrollToLeft = this.scrollToLeft.bind(this);
    this.goToSettings = this.goToSettings.bind(this);
    this.addSlotTime = this.addSlotTime.bind(this);
    this.submitData = this.submitData.bind(this);
    this.updateDay = this.updateDay.bind(this);
  }

  componentDidMount() {
    const { selectedDays } = this.state;
    let maxSlotTimesDays = this.findMaxSlot(selectedDays);
    let formateOpeningHours = [];
    if (maxSlotTimesDays) {
      formateOpeningHours = this.formateOpeningHours(
        selectedDays,
        maxSlotTimesDays
      );
    } else {
      const newSlot = { slot1: this.state.emptySlot };
      maxSlotTimesDays = 1;
      formateOpeningHours.push(newSlot);
    }
    this.props.onUpdateRulesStatus()
    this.setState(
      {
        displaySlotTimes: maxSlotTimesDays,
        maxSlotTimesDays,
        formateOpeningHours,
      },
      () => this.goBottomPage()
    );
  }

  formateOpeningHours(selectedDays, maxSlot) {
    const days = Object.keys(selectedDays);
    const arr = this.createArraySlot(maxSlot);
    return days.reduce((acc, day) => {
      const hours = selectedDays[day];
      if (hours.length) {
        acc.map((slot, index) => {
          const keyName = Object.keys(slot);
          if (hours[index]) {
            slot[keyName] = [...slot[keyName], { [day]: hours[index] }];
          } else {
            slot[keyName] = [...slot[keyName], { [day]: "" }];
          }
          return slot;
        });
      } else {
        acc.map((slot) => {
          const keyName = Object.keys(slot);
          if (slot[keyName].length) {
            slot[keyName] = [...slot[keyName], { [day]: "" }];
          } else {
            slot[keyName] = [{ [day]: "" }];
          }
          return slot;
        });
      }
      return acc;
    }, arr);
  }
  async updateTimeRangeDay(e, day, newHours, indexTimeRange, action) {
    let copyDays = Object.assign({}, this.state.selectedDays);
    const { displaySlotTimes, addTimeRangeInProgress } = this.state;
    let sendData = true;

    if (
      (action === "remove" &&
        copyDays[day].length === 1 &&
        !addTimeRangeInProgress) ||
      action === "removeAll"
    ) {
      copyDays[day] = [];
    } else if (
      action === "remove" &&
      !copyDays[day].length &&
      addTimeRangeInProgress
    ) {
      copyDays[day] = [];
      sendData = false;
    } else {
      copyDays[day] = this.updateDay(
        copyDays[day],
        action,
        newHours,
        indexTimeRange
      );
    }
    const newDays = this.updateDaysOfWeek(day, copyDays);
    const formateOpeningHours = this.formateOpeningHours(
      newDays,
      displaySlotTimes
    );
    const dataSent = sendData ? await this.submitData(newDays) : true;
    if (dataSent) {
      this.setState(
        {
          addTimeRangeInProgress: false,
          indexTimeRangeUpdated: -1,
          isUpdateInProcess: false,
          dataSentSuccess: true,
          addingNewHours: false,
          formateOpeningHours,
          slotUpdated: null,
          dayUpdated: "",
          success: true,
        },
        () => {
          this.props.enqueueSnackbar(
            <FormattedMessage
              id="common.success"
              defaultMessage="Vos changements ont bien été pris en compte."
            />,
            { variant: "success" }
          );
          this.props.onUpdate(newDays);
        }
      );
    } else {
      this.displayError("noDatas");
    }
  }

  timeRangeUpdated(indexTimeRange) {
    if (this.state.indexTimeRangeUpdated < 0) {
      this.setState({
        indexTimeRangeUpdated: indexTimeRange,
        isUpdateInProcess: true,
      });
    }
  }

  updateDaysOfWeek(selectedDay, newDay) {
    let copyDays = Object.assign({}, this.state.selectedDays);
    copyDays[selectedDay] = newDay[selectedDay];
    return copyDays;
  }

  createArraySlot(slot) {
    let arr = [];
    for (let i = 0; i < slot; i++) {
      const obj = {
        [`slot${i + 1}`]: [],
      };
      arr.push(obj);
    }
    return arr;
  }

  addTimeRange(e, day, indexSlot = 0) {
    e.preventDefault();
    let copyDay = Object.assign({}, this.state.selectedDays);
    const { displaySlotTimes } = this.state;
    const lastTimeRange = copyDay[day].length
      ? copyDay[day][copyDay[day].length - 1].time2
      : "08:00";
    if (lastTimeRange === "00:00") return;
    const newTimeRange = { time1: lastTimeRange, time2: lastTimeRange };
    copyDay[day] = [...copyDay[day], newTimeRange];
    const newDays = this.updateDaysOfWeek(day, copyDay);
    const formateOpeningHours = this.formateOpeningHours(
      newDays,
      displaySlotTimes
    );
    this.setState({
      addTimeRangeInProgress: true,
      slotUpdated: indexSlot,
      addingNewHours: true,
      formateOpeningHours,
      dayUpdated: day,
    });
  }

  displayError(type) {
    switch (type) {
      case "time":
        this.props.enqueueSnackbar(
          <FormattedMessage
            id="error-change-time-range"
            defaultMessage="Merci de renseigner d’abord l’heure de fin du créneau."
          />,
          { variant: "error" }
        );
        break;
      case "noData":
        this.props.enqueueSnackbar(
          <FormattedMessage
            id="common.errorRequest"
            defaultMessage="Oups... nous rencontrons un problème avec l'enregistrement de vos données"
          />,
          { variant: "error" }
        );
        break;
      default:
        return;
    }
    this.setState({ isUpdateInProcess: false });
  }

  goBottomPage() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  scrollToLeft() {
    const elm = document.getElementById("container-slots");
    elm.scrollLeft = elm.offsetWidth;
  }

  goToSettings(e) {
    e.preventDefault();
    this.setState({ rdcToSettings: true });
  }

  addSlotTime(e) {
    e.preventDefault();
    const {
      formateOpeningHours,
      displaySlotTimes,
      maxSlotTimesDays,
      emptySlot,
    } = this.state;
    const diff = displaySlotTimes - maxSlotTimesDays;
    if (!diff) {
      const newQtiesSlot = displaySlotTimes + 1;
      const newSlot = { [`slot${newQtiesSlot}`]: emptySlot };
      const newFormateOpeningHours = [...formateOpeningHours, newSlot];
      this.setState(
        {
          displaySlotTimes: newQtiesSlot,
          formateOpeningHours: newFormateOpeningHours,
        },
        () => this.scrollToLeft()
      );
    }
    return;
  }

  findMaxSlot(hoursDays) {
    const nameDays = Object.keys(hoursDays);
    return nameDays.reduce((acc, day) => {
      const slotLength = hoursDays[day].length;
      if (slotLength > acc) {
        acc = slotLength;
      }
      return acc;
    }, 0);
  }

  async submitData(data) {
    const { restaurantId } = this.state;
    const openingHours = {
      openingDate: data,
    };

    const res = await CallApi(
      "put",
      "restaurants",
      restaurantId,
      openingHours,
      this.props.token
    );
    return !!res[0];
  }

  updateDay(day, action, newHours, indexTimeRange) {
    const { addingNewHours } = this.state;
    let newDay = [];

    if (day.length) {
      switch (action) {
        case "update":
          return addingNewHours
            ? (newDay = [...day, newHours])
            : ((day[indexTimeRange] = newHours), (newDay = day));
        case "remove":
          day.splice(indexTimeRange, 1);
          newDay = day;
          break;
        default:
          return newDay;
      }
    } else {
      newDay.push(newHours);
    }

    return newDay;
  }

  render() {
    const {
      addTimeRangeInProgress,
      formateOpeningHours,
      isUpdateInProcess,
      rdcToSettings,
      selectedDays,
      slotUpdated,
      dayUpdated,
    } = this.state;
    const days = Object.keys(selectedDays);
    return (
      <div id="hours">
        <React.Fragment>
          <div className="container-schedule-N-add-time">
            <div className="container-schedule-days">
              <div className="container-display-day">
                <p></p>
                {days.map((day, index) => (
                  <p key={`${index}${day}`}>
                    <FormattedMessage id={`day.${day}`} defaultMessage={day} />
                  </p>
                ))}
              </div>
              <div id="container-slots">
                <Slots
                  addTimeRangeInProgress={addTimeRangeInProgress}
                  updateTimeRangeDay={this.updateTimeRangeDay}
                  formateOpeningHours={formateOpeningHours}
                  timeRangeUpdated={this.timeRangeUpdated}
                  isUpdateInProcess={isUpdateInProcess}
                  displayError={this.displayError}
                  addTimeRange={this.addTimeRange}
                  selectedDays={selectedDays}
                  slotUpdated={slotUpdated}
                  dayUpdated={dayUpdated}
                />
              </div>
              <div className="container-switchs">
                <p>
                  <FormattedMessage
                    id="common.activation"
                    defaultMessage="Activation"
                  />
                </p>
                {days.map((day, index) => {
                  const hoursOfDay = selectedDays[day];
                  return (
                    <SwitchHours
                      updateTimeRangeDay={this.updateTimeRangeDay}
                      addTimeRange={this.addTimeRange}
                      hoursOfDay={hoursOfDay}
                      dayUpdated={dayUpdated}
                      key={index}
                      day={day}
                    />
                  );
                })}
              </div>
            </div>
            <div className="container-add-slot-close-range">
              <p onClick={(e) => this.goToSettings(e)}>
                <FormattedMessage
                  id="common.addClose"
                  defaultMessage="Ajouter période de fermeture."
                />
              </p>
              <p onClick={(e) => this.addSlotTime(e)}>
                <FormattedMessage
                  id="common.addSlot"
                  defaultMessage="Ajouter un créneau"
                />
              </p>
            </div>
          </div>
        </React.Fragment>
        {rdcToSettings ? <Redirect to="/reglages" /> : ""}
      </div>
    );
  }
}

OpeningHours.propTypes = {
  openingHours: PropTypes.object.isRequired,
  restaurantId: PropTypes.string.isRequired,
};

export default withSnackbar(OpeningHours);

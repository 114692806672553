import React from 'react';

import faTrash from '@fortawesome/fontawesome-free-solid/faTrashAlt';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

const ButtonPicker = ({
  isUpdateInProcess = true,
  addTimeIsInProgress,
  removeData
}) => {
  return (
    <div
      className={`container-button-picker`}
    >
      <FontAwesomeIcon
        className={`admin-fa-icon icon-remove ${
          isUpdateInProcess || addTimeIsInProgress ? 'disabled-button' : ''
        }`}
        onClick={
          isUpdateInProcess || addTimeIsInProgress ? null : e => removeData(e)
        }
        icon={faTrash}
      />
    </div>
  );
};
export default ButtonPicker;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class BeingCreated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      email: props.email,
      phone: props.phone
    };
  }

  componentWillMount() {
    document.body.setAttribute('id', 'being-created');
  }

  componentWillUnmount() {
    document.body.setAttribute('id', '');
  }

  render() {
    return (
      <div className="being-created">
        <div className="image">
          <h2>{this.state.name}</h2>
        </div>
        <FormattedMessage
          id="beingCreated-text"
          defaultMessage={`La réservation pour le restaurant {name} est actuellement indisponible. Merci de réessayer ultérieurement.`}
          values={{
            name: <span className="name">{this.state.name}</span>
          }}
        />
      </div>
    );
  }
  static defaultProps = {
    name: '',
    email: ''
  };
}

BeingCreated.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string
};
export default BeingCreated;

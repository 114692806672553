import React from 'react';
import Menu from './Menu';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle';
import faCalendar from '@fortawesome/fontawesome-free-solid/faCalendar';
import faHome from '@fortawesome/fontawesome-free-solid/faHome';
import logoLaddition from './../../images/logo_laddition.png';
import { getHostUrl } from '../../utils/function';

const Header = props => {
	const { title, displayMenu, hasECGroup } = props;

	const logout = e => {
		e.preventDefault();
		const urlLogout = getHostUrl('auth') + '/logout';
		try {
			window.localStorage.clear();
			window.location.replace(urlLogout);
		} catch (e) {
			return e;
		}
	};

	const resaAdmin = _ => {
		window.location.replace(
			`${window.location.origin}/booking/${props.restaurantId}`
		);
	};

	return (
		<header>
			<div className="content">
				<div className="circle-orange" />
				<div id="title">
					<h1 className="logo">
						<img src={logoLaddition} alt="logo laddition" /> / La Réservation
					</h1>

					<div id="logout">
						<button onClick={e => logout(e)} className="button-logout">
							<FontAwesomeIcon
								size="2x"
								className="admin-fa-icon"
								icon={faTimesCircle}
							/>
							<FormattedMessage id="logout.cta" defaultMessage="Déconnexion" />
						</button>
						{hasECGroup && (
							<button
								onClick={() => window.open(getHostUrl('home'), '_self')}
								className="button-logout">
								<FontAwesomeIcon
									size="2x"
									className="admin-fa-icon"
									icon={faHome}
								/>
								<FormattedMessage
									id="home.cta"
									defaultMessage="Mon Espace Client"
								/>
							</button>
						)}
						<button onClick={resaAdmin} className="button-logout">
							<FontAwesomeIcon
								size="2x"
								className="admin-fa-icon"
								icon={faCalendar}
							/>{' '}
							<FormattedMessage
								id="resaAdmin.cta"
								defaultMessage="Passer une réservation en admin"
							/>
						</button>
					</div>
				</div>
				<h1 className="title">
					<FormattedMessage id={`admin.${title}`} defaultMessage="" />
				</h1>
				{!!displayMenu ? <Menu /> : ''}
			</div>
			<div className="bg-white" />
		</header>
	);
};

Header.propTypes = {
	title: PropTypes.string.isRequired,
	displayMenu: PropTypes.bool.isRequired,
	hasECGroup: PropTypes.bool,
};

export default Header;

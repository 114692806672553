import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <ul>
          <li>
            <NavLink activeClassName={'active'} to={'/informations'}>
              <p>
                <FormattedMessage
                  id="menu.info"
                  defaultMessage="Informations"
                />
              </p>
              <hr />
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={'active'} to="/horaires">
              <p>
                <FormattedMessage id="menu.hour" defaultMessage="Horaires" />
              </p>
              <hr />
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={'active'} to="/reglages">
              <p>
                <FormattedMessage id="menu.setting" defaultMessage="Réglages" />
              </p>
              <hr />
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={'active'} to="/integration">
              <p>
                <FormattedMessage
                  id="menu.integration"
                  defaultMessage="Intégration"
                />
              </p>
              <hr />
            </NavLink>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

export default Menu;

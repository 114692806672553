import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import moment from 'moment';
import 'moment/locale/fr';
import { FormattedMessage } from 'react-intl';

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import CallApi from '../../utils/CallApi';

class DayPickerToFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: {
        from: null,
        to: null
      },
      editRange: {},
      errors: ''
    };
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
      editRange: {}
    };
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.editRange !== prevProps.editRange) {
      return {
        editRange: nextProps.editRange
      };
    }

    return null;
  }

  handleDayClick = day => {
    const range = DateUtils.addDayToRange(day, this.state);
    const state = this.state;

    this.setState(range);

    if (state.range && !state.range.from && !state.range.to) {
      range.from = day;
    } else {
      range.to = day;
    }

    this.setState(
      {
        range
      },
      () => {
        if (this.state.range && this.state.range.from && this.state.range.to) {
          // convert format date into us date
          const newClosingDays = {
            day1: moment(range.from).format('YYYY-MM-DD'),
            day2: moment(range.to).format('YYYY-MM-DD')
          };
          this.props.handleAddClosingDays(newClosingDays);
        }
      }
    );
  };

  handleResetClick = () => {
    this.setState(this.getInitialState());
  };

  async handleClosingDays(e) {
    e.preventDefault();

    let newClosingDays = {
      restaurantId: this.props.restaurantId,
      day1: moment(this.state.startDate).format('YYYY-MM-DD'),
      day2: moment(this.state.endDate).format('YYYY-MM-DD')
    };

    try {
      const times = await CallApi(
        'post',
        'closingDays',
        '',
        newClosingDays,
        this.props.token
      );
      this.props.onUpdateClosingDays(times);
    } catch (e) {
      this.setState({
        errors: (
          <FormattedMessage
            id="error-requestAdmin"
            defaultMessage="Oups.. nous rencontrons un problème avec la modification de vos données, veuillez retenter plus tard"
          />
        )
      });
    }
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div className={`container`}>
        {this.state.errors ? <p>{this.state.errors} </p> : ''}
        <DayPicker
          className="Selectable"
          numberOfMonths={this.props.numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
        />
      </div>
    );
  }
  static defaultProps = {
    numberOfMonths: 1,
    startDate: {},
    endDate: {},
    editRange: {
      from: null,
      to: null,
      id: null,
      position: null
    }
  };
}

DayPickerToFrom.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  editRange: PropTypes.object,
  handleAddClosingDays: PropTypes.func.isRequired
};

export default DayPickerToFrom;

import { Alert } from "@material-ui/lab";
import decode from 'jwt-decode';
import React  from "react";
import { AdminManager, getCookie, getHostUrl } from "../../utils/function";
import { FormattedMessage } from "react-intl";

export default function AdminInfo(){

  const isAdmin = AdminManager.isAdmin();
  const isError = AdminManager.isAdminError();
  const isLogged = AdminManager.isLogged();

  const handleLogOut = () => {
    const authUrl = getHostUrl('auth');
    window.open(authUrl + '?scope=reservation', '_self')
  }

  const getEmail = () => {
    const refreshToken = getCookie('refreshToken');
    const decoded = decode(refreshToken);
    return decoded.username
  }


  const renderAdminInfo = () => {
    if (isError || (!isLogged && isAdmin)) {
      return (
        <Alert severity="error">
          <strong>
            <FormattedMessage id="adminModeError1" defaultMessage="Mode Admin - "/> 
          </strong>
          <FormattedMessage id="adminModeError2" defaultMessage="le mode admin n'est pas disponible. Vous devez vous reconnecter si vous souhaitez l'utiliser."/> 
          <button style={{ textDecoration: 'underline'}} onClick={handleLogOut}><FormattedMessage id="reconnexion" defaultMessage="Me reconnecter" /></button>
        </Alert>
      )}
      if (isAdmin && isLogged) {
        return (
          <Alert severity="info">
            <strong>
              <FormattedMessage id="adminMode1" defaultMessage="Mode Admin - " />
            </strong>
            <FormattedMessage id="adminMode2" defaultMessage="Certains champs sont facultatifs et la réservation sera automatiquement validée" />
          </Alert>
      )}
      if (isLogged && !isAdmin) {
        return (
          <Alert severity="warning">
            <FormattedMessage id="adminModeWarning1" defaultMessage="Vous êtes connecté avec le compte " />
            <strong>{getEmail()}</strong>
            <FormattedMessage id="adminModeWarning2" defaultMessage=" Vous devez vous reconnecter pour utiliser le mode admin sur cet établissement." />
            <button style={{ textDecoration: 'underline'}} onClick={handleLogOut}><FormattedMessage id="reconnexion" defaultMessage="Me reconnecter" /></button>
          </Alert>
      )}
  
    return null;
  }

  return renderAdminInfo();
}
import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import DisplayTimePicker from './TimePicker';

class Slots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true
    };
    this.deleteTimeRanges = this.deleteTimeRanges.bind(this);
    this.checkIndex = this.checkIndex.bind(this);
    this.addRange = this.addRange.bind(this);
  }

  deleteTimeRanges(e, day) {
    this.setState({ checked: false }, () =>
      this.props.updateTimeRangeDay(e, day, {}, null, 'removeAll')
    );
  }

  checkIndex(indexSlot, hoursOfDay) {
    const lastIndex = hoursOfDay.length - 1;
    const diff = indexSlot - lastIndex;
    return diff > 1 ? lastIndex + 1 : indexSlot;
  }

  addRange(e, day, indexSlot, hoursOfDay) {
    const { addTimeRange } = this.props;
    const indexSelectedSlot = hoursOfDay.length
      ? this.checkIndex(indexSlot, hoursOfDay)
      : 0;

    addTimeRange(e, day, indexSelectedSlot);
  }

  render() {
    const {
      addTimeRangeInProgress,
      formateOpeningHours,
      updateTimeRangeDay,
      isUpdateInProcess,
      timeRangeUpdated,
      displayError,
      selectedDays,
      slotUpdated,
      dayUpdated
    } = this.props;

    return formateOpeningHours.map((slot, indexSlot) => {
      const slotKey = Object.keys(slot);
      const slotValues = slot[slotKey];
      return (
        <div className="container-slot" key={indexSlot}>
          <p className="title-slot">
            <FormattedMessage
              id="common.slot"
              defaultMessage="Créneau {number}"
              values={{ number: indexSlot + 1 }}
            />
          </p>
          {slotValues.map((day, index) => {
            const findDay = Object.keys(day);
            const hours = day[findDay];
            const hoursOfDay = selectedDays[findDay];
            if (hours) {
              return (
                <DisplayTimePicker
                  addTimeRangeInProgress={addTimeRangeInProgress}
                  updateTimeRangeDay={updateTimeRangeDay}
                  handleCloseSnack={this.handleCloseSnack}
                  isUpdateInProcess={isUpdateInProcess}
                  arrayLength={hoursOfDay.length - 1}
                  timeRangeUpdated={timeRangeUpdated}
                  displayError={displayError}
                  slotUpdated={slotUpdated}
                  dayUpdated={dayUpdated}
                  hoursOfDay={hoursOfDay}
                  indexSlot={indexSlot}
                  hours={hours}
                  day={findDay}
                  key={index}
                />
              );
            } else {
              return (
                <div
                  className="container-time-picker-empty"
                  onClick={
                    isUpdateInProcess
                      ? null
                      : e => this.addRange(e, findDay, indexSlot, hoursOfDay)
                  }
                  key={index}
                >
                  <span>/ - /</span>
                </div>
              );
            }
          })}
        </div>
      );
    });
  }
}

export default Slots;

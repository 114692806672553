import React, { Component } from 'react';

import ButtonPicker from './ButtonPicker';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexSlot: props.indexSlot || 0,
      hours: props.hours || {},
      firstHour: moment('08:00', 'HH:mm'),
      secondHour: moment('16:00', 'HH:mm')
    };
    this.isFirstHourBeforeSecond = this.isFirstHourBeforeSecond.bind(this);
    this.disabledMinutesTime2 = this.disabledMinutesTime2.bind(this);
    this.disabledMinutesTime = this.disabledMinutesTime.bind(this);
    this.findMinutesDisabled = this.findMinutesDisabled.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.deleteTimeRange = this.deleteTimeRange.bind(this);
    this.disabledHours = this.disabledHours.bind(this);
    this.updateHours = this.updateHours.bind(this);
    this.submitHours = this.submitHours.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.openPanel = this.openPanel.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.hours) {
      this.updateHours(nextProps.hours);
    }
  }

  componentDidMount() {
    //CAUSE NE PASSE PAS TOUJOURS WILLRECEIVEPROPS
    if (this.props.hours) {
      this.updateHours(this.props.hours);
    }
  }

  isFirstHourBeforeSecond = hours => {
    const { time1, time2 } = hours;
    const firstHourFormated = moment(time1, 'HH:mm');
    const secondHourFormated = moment(time2, 'HH:mm');
    const midNight = '00:00';

    return (
      firstHourFormated.isBefore(secondHourFormated) ||
      firstHourFormated.isSame(secondHourFormated) ||
      time2 === midNight
    );
  };

  disabledMinutesTime2(hourSelected) {
    switch (hourSelected) {
      case 0:
        return this.findMinutesDisabled(1);
      default:
        return this.disabledMinutesTime(hourSelected);
    }
  }

  disabledMinutesTime(hourSelected) {
    const indexHourChecked = this.props.indexSlot;
    const { hoursOfDay } = this.props;
    if (hoursOfDay.length <= 1) return;

    const minutesDisabled = hoursOfDay.reduce((acc, timeRange, index) => {
      if (index === indexHourChecked) return acc;
      if (index < indexHourChecked) {
        const hourTime2 = Number(timeRange.time2.split(':')[0]);
        if (hourTime2 === hourSelected) {
          const minutesTime2 = Number(timeRange.time2.split(':')[1]);
          const minutes = this.findMinutesDisabled(0, minutesTime2);
          acc = [...acc, ...minutes];
        }
      }
      if (index > indexHourChecked) {
        const hourTime1 = Number(timeRange.time1.split(':')[0]);
        if (hourTime1 === hourSelected) {
          const minutesTime1 = Number(timeRange.time1.split(':')[1]);
          const minutes = this.findMinutesDisabled(minutesTime1, 60);
          acc = [...acc, ...minutes];
        }
      }
      return acc;
    }, []);

    return minutesDisabled;
  }

  findMinutesDisabled(valueInitial = 0, counter = 60) {
    const minutes = [];
    for (let value = valueInitial; value <= counter; value++) {
      minutes.push(value);
    }
    return minutes;
  }

  disabledHours() {
    const indexHourChecked = this.props.indexSlot;
    const { hoursOfDay } = this.props;
    const hoursToDisabled = hoursOfDay.reduce((acc, timeRange, index) => {
      if (index === indexHourChecked) return acc;
      if (index < indexHourChecked) {
        const time2 = Number(timeRange.time2.split(':')[0]);
        for (let value = 0; value < time2; value++) {
          acc.push(value);
        }
      }
      if (index > indexHourChecked) {
        const time = Number(timeRange.time1.split(':')[0]);
        for (let time1 = time + 1; time1 <= 23; time1++) {
          acc.push(time1);
        }
      }
      return acc;
    }, []);
    return hoursToDisabled;
  }

  handleTimeChange(value, StrTimePeriode) {
    const newTime = value;
    this.updateHours({ [StrTimePeriode]: newTime });
  }

  deleteTimeRange(e) {
    const { day, indexSlot, updateTimeRangeDay } = this.props;
    updateTimeRangeDay(e, day, {}, indexSlot, 'remove');
  }

  updateHours(hours) {
    const { secondHour, firstHour } = this.state;
    const secondValue = hours.time2 ? moment(hours.time2, 'HH:mm') : secondHour;
    const firstValue = hours.time1 ? moment(hours.time1, 'HH:mm') : firstHour;

    this.setState({
      firstHour: firstValue,
      secondHour: secondValue
    });
  }

  submitHours(e) {
    const firstValue = this.state.firstHour.format('HH:mm');
    const secondValue = this.state.secondHour.format('HH:mm');
    const newHours = {
      time1: firstValue,
      time2: secondValue
    };
    const isTimeRangeOk = this.isFirstHourBeforeSecond(newHours);
    if (isTimeRangeOk) {
      this.props.updateTimeRangeDay(
        e,
        this.props.day,
        newHours,
        this.props.indexSlot,
        'update'
      );
    } else {
      this.setState(
        {
          firstHour: this.state.secondHour
        },
        () => {
          this.props.displayError('time');
        }
      );
    }
  }

  closePanel(e) {
    this.submitHours(e);
  }

  openPanel() {
    const indexHourChecked = this.props.indexSlot;
    this.props.timeRangeUpdated(indexHourChecked);
  }

  render() {
    const {
      addTimeRangeInProgress,
      isUpdateInProcess,
      slotUpdated,
      dayUpdated,
      indexSlot,
      day
    } = this.props;
    const { secondHour, firstHour } = this.state;
    const addTimeIsInProgress =
      addTimeRangeInProgress &&
      (indexSlot !== slotUpdated || dayUpdated[0] != day[0])
        ? true
        : false;
    return (
      <div className="container-time-picker">
        <div
          className={`content-time
            ${
              isUpdateInProcess || addTimeIsInProgress
                ? 'time-picker-disabled'
                : ''
            }
          `}
        >
          <div className="select-styles">
            <TimePicker
              onChange={value => this.handleTimeChange(value, 'time1')}
              disabledMinutes={this.disabledMinutesTime}
              disabledHours={this.disabledHours}
              className="select select-start"
              disabled={addTimeIsInProgress}
              onClose={this.closePanel}
              placement={'bottomRight'}
              onOpen={this.openPanel}
              inputReadOnly={true}
              showSecond={false}
              value={firstHour}
              minuteStep={15}
            />
            <TimePicker
              onChange={value => this.handleTimeChange(value, 'time2')}
              disabledMinutes={this.disabledMinutesTime2}
              disabledHours={this.disabledHours}
              className="select select-end"
              disabled={addTimeIsInProgress}
              onClose={this.closePanel}
              onOpen={this.openPanel}
              value={secondHour}
              showSecond={false}
              minuteStep={15}
            />
          </div>
        </div>
        <ButtonPicker
          addTimeIsInProgress={addTimeIsInProgress}
          isUpdateInProcess={isUpdateInProcess}
          removeData={this.deleteTimeRange}
        />
      </div>
    );
  }
}

export default Time;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {UnauthorizedServicePage} from "@capps/subuser-tools";
import useAuth from "../hooks/useAuth";

const PrivateRoute = ({ component: Component, ...rest }) => {

	const { isLogged, tryLogin, unauthorized } = useAuth();
	const renderRedirect = (props) => {
		if (isLogged) {
			return <Component {...props} />
		}

		if (unauthorized) {
			return <UnauthorizedServicePage />
		}

		// Make error page
		if (tryLogin && !isLogged) {
			return <Redirect to={{ pathname: '/admin', state: {from: props.location} }} />
		}

		return <></>;
	}

	return <Route
		{...rest}
		render={(props) => renderRedirect(props)}
	/>
};

export default PrivateRoute;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import timesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle';

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.content,
      title: props.title
    };
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (
      nextProps.title !== prevProps.title ||
      nextProps.content !== prevProps.content
    ) {
      return {
        title: nextProps.title,
        content: nextProps.content
      };
    }

    return null;
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner">
          <span onClick={e => this.props.close(e, false)}>
            <FontAwesomeIcon icon={timesCircle} />
          </span>
          <h1>{this.state.title}</h1>
          <div className="content">{this.state.content}</div>
        </div>
      </div>
    );
  }
  static defaultProps = {
    content: '',
    title: ''
  };
}

Popup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default Popup;

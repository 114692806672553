import React from "react";
import { FormattedMessage } from "react-intl";
import logoLaddition from "./../../images/logo_laddition.png";
import { getHostUrl } from "../../utils/function";

const NoRecoveryRestaurantId = (props) => {
  const handleLogin = async (e) => {
    try {
      await window.localStorage.removeItem("login");
      const connectURLAuth = getHostUrl('auth') + '/?scope=reservation';
      window.location.replace(connectURLAuth);

    } catch (e) {
      return null;
    }
  };

  return (
    <div id="no-recovery-restaurantId">
      <header>
        <h1 className="logo">
          <img src={logoLaddition} alt="logo laddition" /> /{" "}
          <FormattedMessage
            id="common.nameApp"
            defaultMessage="La Réservation"
          />
        </h1>
      </header>
      <main>
        <p>
          <FormattedMessage
            id="common.errorMsg1"
            defaultMessage="Oups... Nous rencontrons actuellement un problème avec vos identifiants. Nous vous invitons à vous reconnecter."
          />
        </p>
        <button onClick={(e) => handleLogin(e)} className="is-red">
          <FormattedMessage id="common.login" defaultMessage="se connecter" />
        </button>
        <p>
          <FormattedMessage
            id="common.errorMsg2"
            defaultMessage=" Si l'erreur persiste, veuillez nous contacter à l'adresse mail suivante"
          />{" "}
          :{" "}
          <a href="mailto:commande@laddition.com">
            <FormattedMessage
              id="common.emailContactLaddition"
              defaultMessage="reservation@laddition.com"
            />
          </a>
          .
        </p>
      </main>
    </div>
  );
};

export default NoRecoveryRestaurantId;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TemplateWrapper = (WrappedComponent, props) => {
  class Template extends Component {
    render() {
      const { classNames } = props;
      return (
        <div className="">
          <div className={classNames}>
            <WrappedComponent {...this.props} />
          </div>
        </div>
      );
    }
  }

  props.propTypes = {
    title: PropTypes.string.isRequired
  };

  return Template;
};

export default TemplateWrapper;

import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function NotFound() {
  document.body.setAttribute('id', 'being-created');

  return (
    <div className="being-created">
      <div className="image"><h2>404</h2></div>
      <FormattedMessage id="notFound-text" />
    </div>
  );
}
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CallApi from '../../utils/CallApi';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import Loader from 'react-loader-spinner';
import { AdminManager, getCookie } from '../../utils/function';

class LegalInformation extends Component {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props) {
		super(props);
		this.state = {
			nameRestaurant: props.nameRestaurant,
			specificInformation: props.specificInformation,
			information: '',
			checkPub: false,
			checkCGU: true,
			bookingIsValided: false,
			errors: '',
			menu: props.menu,
			dateNextDay: '',
			timeWithoutPlus: '',
			originURL: window.location.origin,
			loader: false,
			timezone: props.timezone,
			isAdmin: AdminManager.isAdmin(),
		};
	}


  componentWillMount() {
    const storageStillExists = window.localStorage.getItem('nameRestaurant');
    if (!storageStillExists) {
      this.redirectToValidation();
    }

  }

	componentDidMount() {
		this.hydrateStateWithLocalStorage();

		if (this.state.errors !== null || '') {
			this.setState({
				errors: '',
			});
		}

		if (!this.state.bookingIsValided) {
			this.bookingIsValided();
		}

		if (this.props.menu.time.includes('+')) {
			this.ifHoursIsNextMorning(
				this.props.menu.day,
				this.props.menu.time.split('h')
			);
		}
	}

	componentWillReceiveProps(nextProps) {
		this.hydrateStateWithLocalStorage();

		if (
			nextProps.menu !== this.state.menu ||
			nextProps.timezone !== this.state.timezone
		) {
			this.setState({
				menu: nextProps.menu,
				timezone: nextProps.timezone,
			});
		}
	}

	componentDidCatch(err) {
		this.setState({ errors: err.message });
	}

	handleInputChange = e => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name]: value });
		window.localStorage.setItem(name, value);
	};

	// if hours have '+', add one date in selected date and delete '+' for hours
	ifHoursIsNextMorning = (day, bookingTime) => {
		const dateNextDay = moment(day, 'YYYY-MM-DD')
			.add('1', 'days')
			.format('YYYY-MM-DD');

		const timeWithoutPlus = `${bookingTime[0].replace('+ ', '')}:${
			bookingTime[1]
		}`;

		this.setState({
			dateNextDay,
			timeWithoutPlus,
		});

		// save localStorage for next Step(validation)
		window.localStorage.setItem('dateNextDay', dateNextDay);
		window.localStorage.setItem('timeWithoutPlus', timeWithoutPlus);
	};

	handleSubmit = async e => {
		e.preventDefault();
		this.setState({
			loader: true,
		});

		const contact = {
			gender: window.localStorage.getItem('gender')
				? window.localStorage.getItem('gender')
				: '',
			lastname: window.localStorage.getItem('lastname')
				? window.localStorage.getItem('lastname')
				: '',
			firstname: window.localStorage.getItem('firstname')
				? window.localStorage.getItem('firstname')
				: '',
			phone: window.localStorage.getItem('phone')
				? window.localStorage.getItem('phone')
				: '',
			email: window.localStorage.getItem('email')
				? window.localStorage.getItem('email')
				: '',
			society: window.localStorage.getItem('society')
				? window.localStorage.getItem('society')
				: '',
			information: this.state.information,
			checkCGU: this.state.checkCGU,
			checkPub: this.state.checkPub,
		};
		const bookingTime = this.props.menu.time.split('h');
		const date = !!bookingTime[0].includes('+')
			? moment(this.props.menu.day, 'YYYY-MM-DD').add('1', 'days').toDate()
			: moment(this.props.menu.day, 'YYYY-MM-DD').toDate();
		// completeDate for ddb la resa
		const completeDate = moment(date, 'YYYY-MM-DD').set({
			hour: bookingTime[0].includes('+')
				? bookingTime[0].replace('+ ', '')
				: bookingTime[0],
			minute: bookingTime[1],
		});
		const completeDateUTC = moment.tz(
			`${moment(completeDate).format('YYYY-MM-DD')} ${moment(
				completeDate
			).format('HH:mm')}`,
			this.state.timezone
		);
		const bookingIsAfterNow = completeDateUTC.isAfter(moment());
		if (bookingIsAfterNow) {
			let booking = {
				booking: {
					date: moment(completeDateUTC).format('YYYY-MM-DD HH:mmZ'),
					nbGuest: this.props.menu.nbGuest,
					restaurantId: this.props.restaurantId,
					admin: AdminManager.isAdmin(),
				},
			};
			let finalData = Object.assign(booking, contact);
			const response = await CallApi(
				'post',
				'users',
				'',
				finalData,
				AdminManager.isAdmin() ? getCookie('refreshToken') : null
			);
			if (!!response.length && response[0] === 'success') {
				this.props.orderIsBooked(response);
				this.redirectToValidation();
        // Used to hide menu on both mobile and desktop
        // When the user has send the booking to void bugs
        this.props.onUpdate();
			} else {
				const errorHour =
					response.length && response[0].error === 'hours restaurant closed';
				return this.setState({
					errors: (
						<FormattedMessage
							id={errorHour ? 'error-hour' : 'error-request'}
							defaultMessage="Oups... nous rencontrons un problème avec l'envoi de votre demande, veuillez retenter plus tard"
						/>
					),
					loader: false,
				});
			}
		} else {
			this.redirectToValidation(true);
		}
	};

	hydrateStateWithLocalStorage = () => {
		for (let key in this.state) {
			if (window.localStorage.hasOwnProperty(key)) {
				let value = window.localStorage.getItem(key);
				try {
					value = JSON.parse(value);
					this.setState({ [key]: value });
				} catch (e) {
					this.setState({ [key]: value });
				}
			}
		}
		this.setState({ isAuth: AdminManager.isAdmin() });
	};

	bookingIsValided = () => {
		// check if all key is not empty
		const menuInfo = this.props.menu;

		const { contact, day, nbGuest, time } = menuInfo;

		if (contact && day && nbGuest && time) {
			this.setState({
				bookingIsValided: true,
			});
		}
	};

	redirectToValidation = (dateError = false) => {
		this.context.router.history.push({
			pathname: '/validation',
			state: {
				nameRestaurant: this.state.nameRestaurant,
				dateError,
				refreshToken:getCookie('refreshToken'),
				accessToken: window.sessionStorage.getItem('accessToken'),
			},
		});
	};

	render() {
		const props = this.props;
		return (
			<React.Fragment>
				<form
					{...props}
					noValidate
					onSubmit={this.handleSubmit}
					id="validation"
					className="validation">
					<div className="form-content">
						<div className="bloc-label">
							<h4>
								<FormattedMessage
									id="contact.demande"
									defaultMessage="Demandes"
								/>{' '}
								<FormattedMessage
									id="contact.specific"
									defaultMessage="particulières"
								/>
							</h4>
							{this.state.specificInformation ? (
								<p className="info">{this.state.specificInformation}</p>
							) : (
								''
							)}
							<FormattedMessage
								id="contact.placeholder"
								defaultMessage="Message, anniversaire, allergies...">
								{msg => (
									<textarea
										className="textarea"
										placeholder={msg}
										name="information"
										onChange={this.handleInputChange}
										value={this.state.information}
										maxlength="512"
									/>
								)}
							</FormattedMessage>
						</div>
						{!AdminManager.isAdmin() ? (
							<div className="bloc-label">
								<label htmlFor="checkPub" className="checkbox pure-checkbox">
									<input
										id="checkPub"
										type="checkbox"
										name="checkPub"
										checked={this.state.checkPub}
										onChange={this.handleInputChange}
									/>
									<span className="check_mark">
										<FormattedMessage
											id="contact.checkPub"
											defaultMessage={`J'accepte d'être contacté par le restaurant {nameRestaurant} et ses
                    partenaires par email et sms dans le cadre d'opérations marketing`}
											values={{ nameRestaurant: this.state.nameRestaurant }}
										/>
									</span>
								</label>
							</div>
						) : (
							''
						)}

						{this.state.bookingIsValided ? (
							''
						) : (
							<div className="user-form-no-valided error">
								<p>
									<FormattedMessage
										id="warning.formNotComplete"
										defaultMessage="Attention ! Pour pouvoir finaliser votre demande de réservation, il faut remplir le(s) champ(s) suivant(s) :"
									/>
								</p>
								<div className="list">
									{this.props.menu.contact.length === 0 ? (
										<p>
											<FormattedMessage
												id="warning.contact"
												defaultMessage="- vos coordonnées"
											/>
										</p>
									) : (
										''
									)}
									{this.props.menu.day.length === 0 ? (
										<p>
											<FormattedMessage
												id="warning.day"
												defaultMessage="- le jour de la réservation"
											/>
										</p>
									) : (
										''
									)}
									{this.props.menu.nbGuest.length === 0 ? (
										<p>
											<FormattedMessage
												id="warning.nbGuest"
												defaultMessage="- le nombre de convives"
											/>
										</p>
									) : (
										''
									)}
									{this.props.menu.time.length === 0 ? (
										<p>
											<FormattedMessage
												id="warning.time"
												defaultMessage="- l'heure de la réservation"
											/>
										</p>
									) : (
										''
									)}
								</div>
							</div>
						)}
						{this.state.errors ? (
							<div className="bloc-label error-booking">
								<h4>{this.state.errors} </h4>
							</div>
						) : (
							''
						)}
						<div className="submit-form">
							<button
								onSubmit={this.handleSubmit}
								className={`is-red ${
									this.state.bookingIsValided &&
									!this.state.loader &&
									this.state.errors.length === 0
										? 'isValidated'
										: ''
								}`}>
								{' '}
								{this.state.loader ? (
									<Loader
										type="ThreeDots"
										color="#fff"
										height="10"
										width="15"
									/>
								) : (
									''
								)}
								<FormattedMessage
									id="common.validate"
									defaultMessage="Valider"
								/>
							</button>
						</div>
					</div>
					<div className="legal-info">
						<p>
							<FormattedMessage
								id="contact.legalInfo"
								defaultMessage="Données récoltées via la société ADSTELLAM (RCS Bordeaux 752 972 075) à des fins d’envoi d’offres commerciales ou d'informations relatives à la réservation (confirmation, annulation, demandes d'avis), relatives à l’enseigne du {nameRestaurant}. Les données récoltées pourront être croisées avec celles d’ores et déjà détenues par l'enseigne {nameRestaurant}. Conformément à la loi Informatique et Liberté, vous disposez d’un droit d’accès, de rectification et de suppression des données vous concernant. Pour exercer ces droits, merci d’envoyer un courriel à {contact}."
								values={{
									nameRestaurant: this.state.nameRestaurant,
									contact: (
										<a href="mailto:confidentialite@laddition.com">
											confidentialite@laddition.com
										</a>
									),
								}}
							/>
						</p>
						<p className="cgu-link">
							<FormattedMessage
								id="contact.legalInfo2bis"
								defaultMessage="En validant ce formulaire, j’accepte les {cgu} et la {privacyPolicy}."
								values={{
									cgu: (
										<FormattedMessage id="contact.checkCGULink">
											{message => (
												<a
													href="https://www.laddition.com/fr/cgu"
													target="_blank"
													rel="noopener noreferrer">
													{' '}
													{message}
												</a>
											)}
										</FormattedMessage>
									),
									privacyPolicy: (
										<FormattedMessage id="contact.privacyPolicy">
											{message => (
												<a
													href="https://www.laddition.com/fr/conditions-addition-reservation"
													target="_blank"
													rel="noopener noreferrer">
													{' '}
													{message}
												</a>
											)}
										</FormattedMessage>
									),
								}}
							/>
						</p>
					</div>
				</form>
				{this.state.showFormSuccess ? this._renderSuccessMessage() : null}
			</React.Fragment>
		);
	}

	static defaultProps = {
		nameRestaurant: 0,
		menu: {},
		timezone: '' /* Intl.DateTimeFormat().resolvedOptions().timeZone */,
	};
}

LegalInformation.propTypes = {
	nameRestaurant: PropTypes.string.isRequired,
	onUpdate: PropTypes.func.isRequired,
	menu: PropTypes.object.isRequired,
	specificInformation: PropTypes.string,
	timezone: PropTypes.string.isRequired,
};

export default LegalInformation;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TemplateWrapper = (WrappedComponent, props) => {
  class Template extends Component {
    logout = () => {
      window.localStorage.removeItem('token');
      this.props.history.push('/login');
    };

    render() {
      const { classNames } = props;
      return (
        <div className="is-ancestor is-admin">
          <div className={classNames}>
            <WrappedComponent {...this.props} />
          </div>
        </div>
      );
    }
  }

  props.propTypes = {
    title: PropTypes.string.isRequired
  };

  return Template;
};

export default TemplateWrapper;

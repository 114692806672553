import React from 'react';

import { FormattedMessage } from 'react-intl';

const UrlCustom = ({ websiteUrl, handleInputUrl, handleBlurUrl }) => {
  return (
    <div className="container-change-url">
      <div className="container-label-input">
        <p className="title-link">
          <FormattedMessage
            id="integrate.updateUrl"
            defaultMessage="Personnaliser votre url :"
          />
        </p>
        <FormattedMessage
          id="integrate.exampleUrl"
          defaultMessage="ex : mon restaurant"
        >
          {msg => (
            <input
              type="text"
              name="url"
              placeholder={msg}
              value={websiteUrl}
              onChange={handleInputUrl}
              onBlur={handleBlurUrl}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

export default UrlCustom;

import decode from 'jwt-decode';
import CallApi from './CallApi';

export const isAuthenticated = _ => {
	return !!window.sessionStorage.accessToken;
};

export const checkTokenValidity = (token) => {
  return CallApi(
    'GET',
    'auth',
    '',
    {},
    token,
    false
  ).then((res) => res.success)
}

export const AdminManager = {
  setAdmin: async (refreshToken, restaurantInfos) => {
    if(!refreshToken) {
      window.sessionStorage.clear()
      return;
    }

    const decoded = decode(refreshToken);
    const isTokenValid = await checkTokenValidity(refreshToken);

    if (isTokenValid) {
      window.sessionStorage.setItem('isAdmin', decoded.id === restaurantInfos.idAuth);
      return;
    }
      window.sessionStorage.setItem('isAdmin', 'error')
  },

  isAdmin: () => {
    return !!window.sessionStorage.isAdmin && window.sessionStorage.isAdmin === 'true';
  },

  isAdminError: () => {
    return !!window.sessionStorage.isAdmin && window.sessionStorage.isAdmin === 'error';
  },

  isLogged: () => {
    return !!getCookie('refreshToken');
  },

  removeAdmin: () => {
    window.sessionStorage.removeItem('isAdmin');
  }
};

const splitCookie = () => {
  const cookieDecoded = decodeURIComponent(document.cookie);
  return cookieDecoded.split('; ').map((cookie) => {
    const [name, value] = cookie.split('=');
    return { name, value }
  })
}

export const getCookie = (cookieName) => {
  const cookies = splitCookie();
  const cookie = cookies.find((cookie) => cookie.name === cookieName)
  return cookie ? cookie.value : null;
}

export const decodeToken = (token) => {
  return decode(token);
}

export const getHostUrl = redirect => {
  const host = window.location.hostname;
  const urls = {
    localhost: {
      auth: 'http://localhost:3000',
      home: `http://localhost:3300`,
    },
    'reservation.laddit.io': {
      auth: 'https://auth.laddit.io',
      home: `https://espace-client.laddit.io`,
    },
    'reservation.laddition.com': {
      auth: 'https://auth.laddition.com',
      home: `https://espace-client.laddition.com`,
    },
  };
  return urls[host][redirect];
};
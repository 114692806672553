import React, { Component, createContext } from 'react';
import { FormattedMessage } from 'react-intl';
import check from './../../images/svg/valider.svg';
import DisplayWordingNbGuest from './../../utils/DisplayWordingNbGuest';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AdminManager } from '../../utils/function';

class Validation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			booking: {},
			society: '',
			information: '',
			strDate: '',
			dateNextDay: '',
			timeWithoutPlus: '',
			dateError: false,
		};
	}

	componentWillMount() {
		window.addEventListener('unload', this.deleteLocalStorage);
	}

	componentWillUnmount() {
		window.removeEventListener('unload', this.deleteLocalStorage);
	}

	componentDidMount() {
		const { state } = this.props.location;
		if (state && state.hasOwnProperty('dateError') && state.dateError) {
			this.deleteLocalStorage();
			this.setState({ dateError: state.dateError }, () =>
				this.props.sendBooking()
			);
			return;
		}
		if (!this.state.date) {
			this.setState(
				{
					booking: JSON.parse(window.localStorage.getItem('menu')),
					society: window.localStorage.getItem('society'),
					information: window.localStorage.getItem('information'),
					dateNextDay: window.localStorage.getItem('dateNextDay'),
					timeWithoutPlus: window.localStorage.getItem('timeWithoutPlus'),
				},
				() => {
					this.bookingDateiSTodayOrTomorrow();
					localStorage.clear();
					this.retreiveTokens();
				}
			);
		}
	}

	deleteLocalStorage = () => {
		localStorage.clear();
		sessionStorage.clear();
		this.retreiveTokens();
	};

	retreiveTokens = _ => {
		const { state } = this.props.location;
		if (state?.accessToken)
			sessionStorage.setItem('accessToken', state.accessToken);
	};

	/**
	 * if date is today or tomorrow
	 */
	bookingDateiSTodayOrTomorrow = () => {
		if (this.state.booking) {
			const today = new moment().format('YYYY-MM-DD');
			const tomorrow = moment(today, 'YYYY-MM-DD')
				.add(1, 'days')
				.format('YYYY-MM-DD');
			const bookingDate = this.state.dateNextDay
				? this.state.dateNextDay
				: this.state.booking.day;
			if (bookingDate === today) {
				this.setState({
					strDate: (
						<FormattedMessage
							id="calendar.today"
							defaultMessage="Aujourd'hui"
						/>
					),
				});
			}
			if (bookingDate === tomorrow) {
				this.setState({
					strDate: (
						<FormattedMessage id="calendar.tomorrow" defaultMessage="Demain" />
					),
				});
			}
		}
	};

	newBooking = e => {
		e.preventDefault();
		const origin = window.location.origin;
		const pathname = window.location.pathname;
		window.location.replace(
			`${origin}${pathname}`
		);
	};

	render() {
		const bookingDate = () => {
			if (this.state.strDate) {
				return this.state.strDate;
			}
			if (this.state.booking && this.state.booking.time) {
				if (this.state.booking.time.includes('+')) {
					return moment(this.state.dateNextDay, 'YYYY-MM-DD').format(
						'DD/MM/YYYY'
					);
				} else {
					return moment(this.state.booking.day, 'YYYY-MM-DD').format(
						'DD/MM/YYYY'
					);
				}
			}
		};
		if (!this.state.dateError) {
			if (this.state.booking) {
				const labelConfirm = AdminManager.isAdmin()
					? 'validationAdmin.bold'
					: 'validation.bold';

				return (
					<div id="validation-booking">
						<div className="icon-menu">
							<img src={check} alt="check" />
						</div>
						<p className="information">
							<FormattedMessage
								id="validation.messageValidation"
								defaultMessage="Votre demande de réservation est complète"
							/>
							<span className="bold">
								<FormattedMessage
									id={labelConfirm}
									defaultMessage="Vous recevrez un email de confirmation ou d’annulation dès que le restaurant aura réceptionné votre demande."
								/>
							</span>
							<span>{this.state.nameRestaurant}</span>
						</p>
						<div className="booking-information">
							<p>{bookingDate()}</p>
							<p>
								{this.state.timeWithoutPlus
									? this.state.timeWithoutPlus
									: this.state.booking.time}
							</p>
							<p>{DisplayWordingNbGuest(this.state.booking.nbGuest)}</p>
							<p>{this.state.booking.contact}</p>
							{this.state.society ? <p>{this.state.society}</p> : ''}
							{this.state.information ? <p>{this.state.information}</p> : ''}
							<p>
								<FormattedMessage
									id="common.demandeSent"
									defaultMessage="Demande de réservation envoyée"
								/>
							</p>
						</div>
						<div className="cta-newBooking">
							<button className="is-red" onClick={e => this.newBooking(e)}>
								<FormattedMessage
									id="validation.newBookingCTA"
									defaultMessage="Nouvelle réservation"
								/>
							</button>
						</div>
					</div>
				);
			} else {
				return (
					<div id="validation-reload">
						<h3>
							<FormattedMessage
								id="common.warningValidation"
								defaultMessage="Attention !"
							/>
						</h3>
						<h3>
							<FormattedMessage
								id="validation.reloadTitle"
								defaultMessage="Votre demande de réservation a déjà été envoyée."
							/>
						</h3>
						<p>
							<FormattedMessage
								id="validation.newBooking"
								defaultMessage="Si vous souhaitez effectuer une nouvelle réservation, cliquez sur le bouton ci-dessous"
							/>
						</p>

						<Link
							to="/date"
							className="is-red"
							onClick={e => this.newBooking(e)}>
							<FormattedMessage
								id="validation.newBookingCTA"
								defaultMessage="Nouvelle réservation"
							/>
						</Link>
					</div>
				);
			}
		} else {
			return (
				<div id="validation-reload">
					<h3>
						<FormattedMessage
							id="common.warningValidation"
							defaultMessage="Attention !"
						/>
					</h3>
					<h3>
						<FormattedMessage
							id="validation.dateError"
							defaultMessage="La date de réservation que vous avez choisie est passée."
						/>
					</h3>
					<p>
						<FormattedMessage
							id="validation.newBooking"
							defaultMessage="Si vous souhaitez effectuer une nouvelle réservation, cliquez sur le bouton ci-dessous"
						/>
					</p>

					<Link to="/date" className="is-red" onClick={e => this.newBooking(e)}>
						<FormattedMessage
							id="validation.newBookingCTA"
							defaultMessage="Nouvelle réservation"
						/>
					</Link>
				</div>
			);
		}
	}
}

Validation.propTypes = {
	nameRestaurant: PropTypes.string.isRequired,
	sendBooking: PropTypes.func.isRequired,
};

export default Validation;

import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * display wording with plural or not
 */
const displayWordingNbGuest = nbGuest => {
  if (nbGuest) {
    if (nbGuest === '1') {
      return (
        <React.Fragment>
          {nbGuest}{' '}
          <FormattedMessage
            id="common.nbGuestSingle"
            defaultMessage="Couvert"
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {nbGuest}{' '}
          <FormattedMessage id="common.nbGuest" defaultMessage="couverts" />
        </React.Fragment>
      );
    }
  } else {
    return (
      <FormattedMessage id="menu.client-diner" defaultMessage="couverts" />
    );
  }
};

export default displayWordingNbGuest;

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import IndexAdmin from './IndexAdmin';
import PrivateRoute from './../containers/PrivateRoute';
import EmailValidation from '../components/admin/EmailValidation';
import IndexUser from './IndexUser';

import './../styles/Common.scss';
import 'purecss/build/pure-min.css';
import 'purecss/build/grids-responsive-min.css';

const App = () => {
  return (
      <Router>
        <React.Fragment>
          <Route path="/booking/:id" component={IndexUser} />
          <Switch>
            <Redirect exact from="/" to="/admin" />
            <PrivateRoute path="/admin" component={IndexAdmin} />
          </Switch>
          <Route
            path="/bookingConfirm/:id/:restaurant"
            component={EmailValidation}
          />
        </React.Fragment>
      </Router>
  );
};

export default App;

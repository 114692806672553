import React from 'react';
import PropType from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const Day = props => {
  const makeClassNames = () => {
    if (props.unavailable) {
      return 'content-day unavailable';
    } else if (props.selected) {
      return 'content-day selected';
    } else {
      return 'content-day';
    }
  };
  const renderToday = () => {
    if (moment().isSame(props.day, 'day')) {
      return true;
    }
  };
  const { nameDay, numberDay } = props;
  const name = props.day.format(nameDay || 'dddd').toLowerCase();
  const date = props.day.format(numberDay || 'D');
  const formatedDate = moment(props.day).format('YYYY-MM-DD');
  return (
    <button
      disabled={props.unavailable}
      onClick={() => props.click(props.day)}
      className="cta-day"
    >
      <div className="name-day">
        <p className={renderToday() === true ? 'isToday' : ''}>
          <FormattedMessage id={`day.${name}`} defaultMessage={name} />
        </p>
      </div>
      <div
        className={`${makeClassNames()} ${
          props.userDay &&
          moment(props.userDay, 'YYYY-MM-DD').isSame(formatedDate)
            ? 'active'
            : ''
        } `}
      >
        <div>{date}</div>
      </div>
    </button>
  );
};

Day.propTypes = {
  day: PropType.object.isRequired,
  selected: PropType.bool,
  click: PropType.func.isRequired,
  unavailable: PropType.bool,
  userDay: PropType.string
};

export default Day;
